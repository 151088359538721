import {Inject, Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {IAppState} from "../../../../store/state/app.state";
import {LoggerService} from "../../logger.service";
import {ClientStorageEntities} from "../../../models/client-storage.model";
import {BaseLocalStorageOrganization, BROWSER_STORAGE} from "./base-local-storage-organization";
import {LoadClientStorageService} from "../load-client-storage.service";
import {DashboardType} from "../../../components/dynamic-dashboard/models/dashboards.model";
import {SingleWidget, WidgetType} from "../../../components/dynamic-dashboard/models/widget.model";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageDynamicDashboardService extends BaseLocalStorageOrganization {

  constructor(protected store: Store<IAppState>,
              protected loggerFactory: LoggerService,
              protected loadClientStorageService: LoadClientStorageService,
              @Inject(BROWSER_STORAGE) public storage: Storage,
  ) {
    super(store, loggerFactory,
      loadClientStorageService,
      storage,
      ClientStorageEntities.DYNAMIC_DASHBOARD_PREFERENCES, 'LocalStorageDynamicDashboardService', {
        preferences: {
          isOwnPreferences: false,
          data: {
            [DashboardType.ORG_HEALTH_DASHBOARD]: {
              widgetArray:
                [
                  new SingleWidget(WidgetType.INSIGHTS_ANOMALIES),
                  new SingleWidget(WidgetType.TENANTS_HEALTH_GRID),
                ]
            }
          }
        }
      })
  }
}
