import { DatabaseSnapshot, AngularFireAction } from '@angular/fire/compat/database';
import { FirebaseOrgBranding } from '../models/branding.model';
import { EntityTreeNode, OrganizationHierarchyTree } from '../models/hierarchy.model';
import { Tenant } from '../models/tenants.model';
import { Organization, OrganizationDetails } from '../models/organizations.model';
import { EntityType } from '../models/entity-type.enum';

export function isFirebaseObjectExist(snapshot: AngularFireAction<DatabaseSnapshot<any>>) {
    return snapshot && snapshot.payload.val();
}
export function isFirebaseObjectHasOwnPreferences(snapshot: AngularFireAction<DatabaseSnapshot<FirebaseOrgBranding>>) {
    return snapshot && snapshot.payload.val() && snapshot.payload.val().preferences.isOwnPreferences;
}
/**
 * Make sure that current entity is child and not the parent himsels
 * For that, the method check id and type
 */
export function isEntityChildOfParent(child: EntityTreeNode, newEntityId: number, newEntityType: EntityType, tree: OrganizationHierarchyTree) {
    return child.value.id == newEntityId && ((newEntityId == tree[0].value.id && newEntityType == tree[0].value.type) || newEntityId !== tree[0].value.id);
}

/**
 * Check type of current entity object
 */
export function getNewEntityType(newEntity: EntityTreeNode | OrganizationDetails | Tenant): any {
    return newEntity.hasOwnProperty('children') ?
        (newEntity as EntityTreeNode).value.type :
        (newEntity as Organization | Tenant).type;
}
/**
 * Return Current Entity Id
 */
export function getNewEntityId(newEntity: EntityTreeNode | OrganizationDetails | Tenant) {
    return newEntity.hasOwnProperty('children') ?
        (newEntity as EntityTreeNode).value.id :
        (newEntity as Organization | Tenant).id;
}
