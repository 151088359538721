import { OrgBranding } from '../../../models/branding.model';
import { ClientStorageEntities, ClientsPreferences, HierarchyWithSnapshot } from '../../../models/client-storage.model';
import { OrganizationHierarchyTree } from '../../../models/hierarchy.model';

export class CrudFireBase {
    hierarchyWithSnapshot: HierarchyWithSnapshot[] = [];
    entireOrgTree: OrganizationHierarchyTree;
    currentFirebaseEntity: ClientStorageEntities;

    getFinalPreferencesVersion(firebasePreferences: ClientsPreferences<any>, companyName: string): ClientsPreferences<any> {
        if (this.currentFirebaseEntity == ClientStorageEntities.ORG_PREFERENCES) {
            return {
                preferences: {
                    data: this.formmatData(firebasePreferences.preferences.data, companyName),
                    isOwnPreferences: false
                }
            }
        }
        else {
            return {
                preferences: { isOwnPreferences: false, data: firebasePreferences.preferences.data }
            };
        }
    }
    formmatData(data: any, companyName: string): {} {
        let formmatData = {};
        for (let [key, value] of Object.entries(data)) {
            if (key == "orgBranding") {
                const branding: OrgBranding = value as OrgBranding;
                formmatData[key] = new OrgBranding(
                    branding.themeColors,
                    companyName ? companyName : null,
                    branding.buttonPalette,
                    { file: null, url: branding.companyLogoAsUrl },
                )
            }
            else formmatData[key] = value;
        }
        return formmatData;
    }
}
