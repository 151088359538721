import {Inject, Injectable} from '@angular/core';
import {BaseLocalStorageOrganization, BROWSER_STORAGE} from "./base-local-storage-organization";
import {Store} from "@ngrx/store";
import {IAppState} from "../../../../store/state/app.state";
import {LoggerService} from "../../logger.service";
import {ClientStorageEntities} from "../../../models/client-storage.model";
import {LoadClientStorageService, NETOP_DEFAULT_ORG_PREFERENCES} from "../load-client-storage.service";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageOrgPreferencesService extends BaseLocalStorageOrganization {

  constructor(protected store: Store<IAppState>,
              protected loggerFactory: LoggerService,
              protected loadClientStorageService: LoadClientStorageService,
              @Inject(BROWSER_STORAGE) public storage: Storage,
  ) {
    super(store, loggerFactory,
      loadClientStorageService,
      storage,
      ClientStorageEntities.ORG_PREFERENCES, 'LocalStorageOrgPreferencesService', {...NETOP_DEFAULT_ORG_PREFERENCES})
  }
}
