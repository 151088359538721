import {Injectable} from '@angular/core';
import {StoreWidgetPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {WidgetsTemplate} from '../models/widget.model';
import {DashboardType} from '../models/dashboards.model';

@Injectable({
  providedIn: 'root'
})
export class WidgetTemplatesService {
  dashboardWidgetsTemplates = {};
  selectedTemplate: WidgetsTemplate;

  constructor(private storeWidgetPreferencesService: StoreWidgetPreferencesService) {
  }

  /**
   * Set the current Dashboard templates array
   * If no template being selected, set the default to the first in array
   */
  setWidgetTemplates(dashboard: DashboardType, widgetsTemplates: WidgetsTemplate[]) {
    this.dashboardWidgetsTemplates[dashboard] = widgetsTemplates;
  }

  /**
   * Update the acutal widgets array on storeWidgetPreferencesService (The source of truth)
   */
  updateWidgetsArray(dashboard: DashboardType) {
    let widgetsTemplatesArray = (this.dashboardWidgetsTemplates[dashboard] as WidgetsTemplate[]);
    if (widgetsTemplatesArray !== undefined) {
      let selectedTemplate = widgetsTemplatesArray.find(template => template.isSelected);
      if (selectedTemplate !== undefined) {
        this.storeWidgetPreferencesService.setWidgetsArray(selectedTemplate.widgetsArray);
      }
    }
  }
}
